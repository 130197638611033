import Swiper from "swiper";
import { Navigation, Pagination, Grid, Autoplay } from 'swiper/modules';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/grid";
import "../css/brand-carousel.pcss";

window.DgcSliderAutoheight = new Swiper('.dgc-autoheight-slider', {
   modules: [ Navigation, Pagination, ],
   loop: true,
   autoHeight: true,
   spaceBetween: 15,
   pagination: {
      el: '.swiper-pagination',
      clickable: true,
   },
   navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
   },
});

window.DgcCarousel = new Swiper('.dgc-brand-carousel', {
   modules: [ Pagination, Navigation, Grid, Autoplay, ],
   // centeredSlides: true,
   // centeredSlidesBounds: true,
   // loop: true,
   // loopedSlides: 8,
   // slidesOffsetAfter: 40,
   // slidesOffsetBefore: 40,
   slidesPerView: 1,
   // spaceBetween: 15,
   threshold: 25,
   // freeMode: true,
   grid: {
      rows: 2,
      fill: 'row'
   },

   breakpoints: {
      800: {
         loop: true,
         slidesPerView: 4,
         slidesPerGroup: 2,
         grid: false,
      },
      1200: {
         loop: true,
         slidesPerView: 6,
         slidesPerGroup: 3,
         grid: false,
      }
   },

   pagination: {
      el: '.swiper-pagination',
   },

   navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
   },
});